<template>
  <div class="searchbox">
    <template v-if="items.length > 0">
      <h1
          style="
          font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
          font-weight: bold;
          font-size: 36px;
          line-height: 47px;
        "
      >
        {{ items.length }}条{{ keyword }}搜索结果
      </h1>

      <v-list three-line>
        <template v-for="(item, index) in items">
          <v-divider class="mx-4" v-if="index != 0" :key="index"></v-divider>
          <v-list-item :key="item.title">
            <v-list-item-content @click="route(index)" style="cursor:pointer;">
              <v-list-item-title
                  v-html="item.headline"
                  style="font-size: 20px"
                  class="searchbox-title"
              ></v-list-item-title>

              <v-list-item-subtitle
                  v-html="item.text"
                  class="substance"
              ></v-list-item-subtitle>

              <v-list-item-subtitle v-html="item.dateTime"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </template>
    <template v-else>
      <v-img src="@/assets/img/zwu.png"></v-img>
      <p
          style="
          text-align: center;
          font-size: 24px;
          font-family: MicrosoftYaHei;
          color: #354052;
        "
      >
        暂无数据
      </p>
    </template>
  </div>
</template>

<script>
import group from "@/views/detail-page/data.js";

export default {
  data() {
    return {
      items: [],
      keyword: "",
    };
  },
  created() {
    this.inquire(this.$route);
  },
  methods: {
    route(i) {
      this.$router.push({
        path: `/detail-page/${group[i].headline}`,
        query: {
          bbs: group.indexOf(group[i]),
        },
      });
    },
    inquire(v) {
      this.keyword = v.query.keyword;
      if (this.keyword) {
        this.keyword = "“" + this.keyword + "”";
      }
      let arr = [];
      for (let i = 0, len = group.length; i < len; i++) {
        let a = new RegExp(v.query.keyword, "i");
        let dateTime, headline, text = "";
        if (group[i].headline.search(a) > -1) {
          headline = group[i].headline.replaceAll(v.query.keyword, `<span style="color:#D65A48">${v.query.keyword}</span>`);
        }
        let index = group[i]["text"].join().search(a);
        if (index > -1) {
          text = group[i]["text"].join();
          text = text.replaceAll(v.query.keyword, `<span style="color:#D65A48">${v.query.keyword}</span>`);
          headline = headline ? headline : group[i].headline;
          dateTime = group[i].dateTime;
        }
        if (headline || text || dateTime) {
          arr.push({headline, text, dateTime})
        }
      }
      this.items = arr;
    },
  },
  watch: {
    $route: {
      handler: function (val) {
        return this.inquire(val);
      },
    },
  },
};
</script>

<style lang="scss">
.searchbox {
  padding-top: 160px;
  width: 82.5%;
  margin-left: auto;
  margin-right: auto;

  .substance {
    color: #333333ff !important;
    line-height: 28px !important;
    padding: 5px 0;
  }

  .searchbox-title {
  }

  .searchbox-title:hover {
    color: #2ca6e0ff !important;
  }
}
</style>